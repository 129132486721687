







































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixin';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {ROUTE_CALENDAR, ROUTE_SHIP_ARRIVAL} from '@/router/routes';
import {authStoreActions} from '@/store/auth.store';
import User, {UserRole} from '@/models/User.model';
import {harborStoreActions} from '@/store/harbor.store';
import Harbor from '@/models/Harbor';

const AuthStore = namespace('auth');
const HarborStore = namespace('harbor');

@Component({
  components: {
    LanguageSelect: () => import(
        /* webpackChunkName: "LanguageSelect" */
        '@/components/shared/LanguageSelect.vue'),
  },
  mixins: [validationMixin],
  validations: {
    username: {required},
    password: {required}
  }
})
export default class LoginViewComponent extends mixins(ErrorMessageHandlerMixin) {

  @AuthStore.Action(authStoreActions.LOGIN)
  public loginAction!: (payload: { username: string, password: string }) => Promise<any>;

  @HarborStore.Action(harborStoreActions.GET_ALL)
  public loadHarbors!: () => Promise<Harbor[]>;

  public username: string | null = '';
  public password: string | null = '';

  public isLoading: boolean = false;

  public async onLogin() {
    // Trigger validation
    this.$v.$touch();

    if (!this.$v.$invalid && !this.isLoading) {
      try {
        this.isLoading = true;

        const user: User = await this.loginAction({ username: this.username!, password: this.password! });
        await this.loadHarbors();

        if (user.userRole === UserRole.SHIP_OWNER) {
          await this.$router.push({name: ROUTE_SHIP_ARRIVAL})
        } else {
          await this.$router.push({name: ROUTE_CALENDAR})
        }
      } catch (e) {
        Vue.notifyErrorSimplified('LOGIN.NOTIFICATION.FAILED');
      } finally {
        this.isLoading = false;
      }
    }
  }
}
